// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig, parseGetParams } from './serviceManager'

export default {
  async getAllListAsync(params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params

    if (!requestConfig.params.maxResultCount) {
      requestConfig.params.maxResultCount = 50
    }

    let page = 0
    let totalCount = 0

    const result = []

    do {
      requestConfig.params.skipCount = page * requestConfig.params.maxResultCount

      // eslint-disable-next-line no-await-in-loop
      const response = await axios.get('/autosupport/veeam', requestConfig)
      totalCount = response.data.totalCount
      result.push(...response.data.items)

      page += 1
    } while (result.length < totalCount)

    return result
  },

  async getAsync(id, config) {
    const result = await axios.get(`/autosupport/veeam/${id}`, buildAxiosRequestConfig(config))
    return result.data
  },

  async triggerBuildAsync(id, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.post(`/autosupport/veeam/${id}/build`, {}, requestConfig)
    return result.data
  },

  async triggerBuildAllAsync(params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.post('/autosupport/veeam/build', {}, requestConfig)
    return result.data
  },

  async getClientPrimaryMatrixAsync(id, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/veeam/${id}/client/primary-matrix`, requestConfig)
    return result.data
  },

  async getClientSecondaryMatrixAsync(id, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/veeam/${id}/client/secondary-matrix`, requestConfig)
    return result.data
  },

  async getClientAsync(systemId, client, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/veeam/${systemId}/client/${client}`, requestConfig)
    return result.data
  },

  async getTaskSessionListAsync(systemId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/veeam/${systemId}/task-session`, requestConfig)
    return result.data
  },

  async getJobAsync(systemId, jobName, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/veeam/${systemId}/job/${jobName}`, requestConfig)
    return result.data
  },

  async getJobSessionListAsync(systemId, jobName, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/veeam/${systemId}/job/${jobName}/session`, requestConfig)
    return result.data
  },

  async getJobSessionAsync(systemId, jobName, jobSessionId, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/veeam/${systemId}/job/${jobName}/session/${jobSessionId}`, requestConfig)
    return result.data
  },

  async getTaskSessionDurationListAsync(systemId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/veeam/${systemId}/task-session/duration`, requestConfig)
    return result.data
  },

  async getJobKpiAsync(systemId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/autosupport/veeam/${systemId}/job/kpi`, requestConfig)
    return result.data
  },
}
