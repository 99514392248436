<template>
  <div>
    <b-alert
      variant="info"
      :show="hasGroupQuery"
    >
      <div class="alert-body">
        <p>
          Data is filtered by job: {{ groupQuery }}.<br>
          The errors only filter is activated per default to increase initial loading speed.
        </p>
      </div>
    </b-alert>

    <b-row>
      <b-col cols="12">
        <b-card>
          <b-form inline>
            <b-form-group
              label="Time range"
              label-sr-only
            >
              <date-range-picker
                v-model="timeRange"
                :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days']"
                :max-selectable-days="30"
                :timerange="asup.timerange"
              />
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="onlyErrors"
                switch
              >
                Errors only
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-input
                v-model="search"
                size="sm"
                placeholder="Search for jobs..."
              />
            </b-form-group>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <b-overlay :show="isLoading">
      <b-card title="Job Duration">
        <b-form>
          <b-form-group label="Show/Hide Jobs">
            <div class="custom-control custom-control-inline custom-checkbox">
              <b-form-checkbox
                v-model="ganttOptions.showBackupJobs"
                class="mr-1"
              >
                Show Backup Jobs
              </b-form-checkbox>
              <b-form-checkbox
                v-model="ganttOptions.showReplicationJobs"
                class="mr-1"
              >
                Show Backup Copy Jobs
              </b-form-checkbox>
            </div>
          </b-form-group>
          <b-form-group label="Other Options">
            <div class="custom-control custom-control-inline custom-checkbox">
              <b-form-checkbox
                v-model="ganttOptions.expandClients"
                class="mr-1"
              >
                Expand clients
              </b-form-checkbox>
            </div>
          </b-form-group>
        </b-form>

        <b-alert
          variant="warning"
          :show="groupArrayOutsideLimit.length > 0 && !hasGroupQuery"
        >
          <div class="alert-body">
            <b-row>
              <b-col>
                <p>Some jobs contains too much data to display. The jobs were excluded from the chart. To display this data anyway, you can click on the individual job in the table to display only this data. Please expect a long loading time of the chart if the amount of data is too large.</p>
              </b-col>
              <b-col>
                <table
                  class="table table-sm"
                >
                  <thead>
                    <tr>
                      <th>Policy</th>
                      <th>Job session count</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="data in groupArrayOutsideLimit"
                      :key="data.group"
                    >
                      <td>
                        <b-link
                          :to="{name: $route.name, query: {jobName: data.group}}"
                          target="_blank"
                        >
                          {{ data.group }}
                        </b-link>
                      </td>
                      <td>{{ data.dataCount }}</td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </div>
        </b-alert>

        <apexchart
          v-if="mounted"
          type="rangeBar"
          :height="chartHeight"
          :options="jobDurationChartOptions"
          :series="[jobDurationChartSeries]"
        />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { $themeColors } from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
import {
  BRow, BCol, BCard, BFormCheckbox, BOverlay, BForm, BFormGroup, BFormInput, BAlert, BLink,
} from 'bootstrap-vue'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import VeeamService from '@/service/veeam.service'
import moment from '@/libs/moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    DateRangePicker,
    BFormCheckbox,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    apexchart: VueApexCharts,
    BAlert,
    BLink,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      timeRange: {
        range: 'last7Days',
      },
      jobDurationData: [],
      onlyErrors: false,
      isLoading: false,
      search: null,
      mounted: false,
      ganttOptions: {
        showBackupJobs: true,
        showReplicationJobs: true,
        expandClients: false,
      },
      jobLimitPerGroup: 1000,
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
    groupQuery() {
      return this.$route.query.jobName
    },
    hasGroupQuery() {
      return this.groupQuery != null
    },
    jobDurationChartOptions() {
      const self = this
      return {
        chart: {
          id: 'jobDurationChart',
          type: 'rangeBar',
          animations: {
            enabled: false,
          },
        },
        noData: {
          text: 'No jobs found',
          style: {
            fontSize: '18px',
          },
        },
        markers: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        yaxis: {
          labels: {
            maxWidth: 500,
            formatter: val => {
              if (typeof (val) === 'string') {
                // eslint-disable-next-line no-unused-vars
                const [jobName, client] = val.split('###') // unique name is seperated by ###

                if (this.ganttOptions.expandClients === false) {
                  return jobName
                }

                return `${jobName} / ${client}`
              }

              return ''
            },
          },
        },
        xaxis: {
          type: 'datetime',
          min: moment(this.timeRange.startDate).tz(self.asup.timezone).valueOf(),
          max: moment(this.timeRange.endDate).tz(self.asup.timezone).valueOf(),
        },
        dataLabels: {
          enabled: false,
        },
        colors: [
          ({ dataPointIndex }) => {
            const item = self.jobDurationChartSeries.data[dataPointIndex]
            if (item) {
              switch (item.properties.status) {
                case 30: return $themeColors.danger
                case 20: return $themeColors.warning
                case 10: return $themeColors.info
                case 0: return $themeColors.success
                default:
                  return $themeColors.secondary
              }
            }

            return $themeColors.secondary
          },
        ],
        tooltip: {
          custom: opts => {
            // eslint-disable-next-line no-unused-vars
            const seriesItem = self.jobDurationChartSeries.data[opts.dataPointIndex]
            const start = moment(opts.y1)
            const end = moment(opts.y2)

            const duration = moment.duration(end.diff(start))

            return `${
              '<table class="table table-sm table-borderless p-1 shadow-lg">'
              + '<tr><th>Job name</th><td>'}${seriesItem.properties.jobName}</td></tr>`
              + `<tr><th>Client</th><td> ${seriesItem.properties.client}</td></tr>`
              + `<tr><th>Type</th><td> ${self.$options.filters.veeamJobTypeDisplayName(seriesItem.properties.jobType)} (${self.$options.filters.veeamJobClassificationDisplayName(seriesItem.properties.jobClassification)})</td></tr>`
              + `<tr><th>Start</th><td> ${self.$options.filters.formatDateTime(start)}</td></tr>` // do not format as TZ, because series is already adjusted with tz
              + `<tr><th>End</th><td> ${self.$options.filters.formatDateTime(end)}</td></tr>`
              + `<tr><th>Duration</th><td> ${duration.humanize()} (${duration.days()}d ${moment.utc(end.diff(start)).format('HH[h] mm[m] ss[s]')})</td></tr>`
              + `<tr><th>Status</th><td> ${self.$options.filters.jobStatusDisplayName(seriesItem.properties.status)}</td></tr>`
            + '</table>'
          },
        },
      }
    },
    chartLineCount() {
      const that = this
      if (this.ganttOptions.expandClients === false) {
        if (this.hasGroupQuery) {
          return 1 // if group filter is activated and clients are NOT expanded, the chart will only have 1 line (the group)
        }
        if (this.jobDurationsByGroupInsideLimit) {
          return Object.keys(this.jobDurationsByGroupInsideLimit).length
        }
        return 0
      }

      const groupClients = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const [jobName, data] of Object.entries(this.jobDurationsByGroupForChart)) {
        for (let i = 0; i < data.length; i += 1) {
          const key = `${jobName}_${data[i].client}`
          const clientHasError = that.hasClientAnyErrors(jobName, data[i].client)
          if (!this.onlyErrors || (this.onlyErrors && clientHasError)) {
            if (!groupClients[key]) {
              groupClients[key] = 1
            }
          }
        }
      }

      return Object.keys(groupClients).length
    },
    chartHeight() {
      return this.calculateChartHeight(this.chartLineCount)
    },
    filteredJobDurationData() {
      let jobs = this.jobDurationData

      if (this.ganttOptions.showBackupJobs === false) {
        jobs = jobs.filter(x => x.jobClassification !== 0)
      }
      if (this.ganttOptions.showReplicationJobs === false) {
        jobs = jobs.filter(x => x.jobClassification !== 1)
      }

      if (this.search) {
        return jobs.filter(x => x.jobName.toLowerCase().includes(this.search.toLowerCase()))
      }

      return jobs
    },
    jobDurationsByGroup() {
      return this.filteredJobDurationData.reduce((p, c) => {
        // eslint-disable-next-line no-param-reassign
        p[c.jobName] = p[c.jobName] || []
        p[c.jobName].push({
          client: c.client,
          jobType: c.jobType,
          jobClassification: c.jobClassification,
          jobSessionId: c.jobSessionId,
          jobName: c.jobName,
          status: c.status,
          start: c.start,
          end: c.end,
        })
        return p
      }, {})
    },
    jobDurationsByGroupInsideLimit() {
      // eslint-disable-next-line no-unused-vars
      return Object.entries(this.jobDurationsByGroup).filter(([key, data]) => data.length <= this.jobLimitPerGroup)
        .reduce((p, d) => {
          // eslint-disable-next-line no-param-reassign
          p[d[0]] = p[d[0]] || []
          // eslint-disable-next-line no-param-reassign, prefer-destructuring
          p[d[0]] = d[1]
          return p
        }, {})
    },
    jobDurationsByGroupOutsideLimit() {
      // eslint-disable-next-line no-unused-vars
      return Object.entries(this.jobDurationsByGroup).filter(([key, data]) => data.length > this.jobLimitPerGroup)
        .reduce((p, d) => {
          // eslint-disable-next-line no-param-reassign
          p[d[0]] = p[d[0]] || []
          // eslint-disable-next-line no-param-reassign, prefer-destructuring
          p[d[0]] = d[1]
          return p
        }, {})
    },
    jobDurationsByGroupForChart() {
      if (!this.hasGroupQuery) {
        return this.jobDurationsByGroupInsideLimit
      }
      // eslint-disable-next-line no-unused-vars
      return Object.entries(this.jobDurationsByGroup).filter(([key]) => key === this.groupQuery)
        .reduce((p, d) => {
          // eslint-disable-next-line no-param-reassign
          p[d[0]] = p[d[0]] || []
          // eslint-disable-next-line no-param-reassign, prefer-destructuring
          p[d[0]] = d[1]
          return p
        }, {})
    },
    groupArrayOutsideLimit() {
      const result = []
      // eslint-disable-next-line no-restricted-syntax
      for (const [group, data] of Object.entries(this.jobDurationsByGroupOutsideLimit)) {
        result.push({
          group,
          dataCount: data.length,
        })
      }

      return result
    },
    jobDurationChartSeries() {
      const that = this
      // eslint-disable-next-line no-unused-vars
      const groups = this.jobDurationsByGroupForChart

      const series = []

      // eslint-disable-next-line no-restricted-syntax
      for (const [jobName, data] of Object.entries(groups)) {
        if (!this.hasGroupQuery || this.groupQuery === jobName) {
          for (let i = 0; i < data.length; i += 1) {
            let key = jobName
            if (this.ganttOptions.expandClients === true) {
              key += `###${data[i].client}`
            }

            // if only errors is true and clients should be expanded, we need to filter data again (backend already provides at onlyErrors only groups which contains data, but it´s not filtered to client level)
            let addItem = true
            if (that.ganttOptions.expandClients && that.onlyErrors) {
              const clientHasError = that.hasClientAnyErrors(jobName, data[i].client)

              addItem = clientHasError
            }

            if (addItem) {
              const seriesItem = {
                x: key,
                y: [
                  this.adjustByTimezone(moment.utc(data[i].start)).valueOf(),
                  this.adjustByTimezone(moment.utc(data[i].end)).valueOf(),
                ],
                properties: {
                  jobName,
                  client: data[i].client,
                  jobType: data[i].jobType,
                  jobClassification: data[i].jobClassification,
                  jobId: data[i].id,
                  status: data[i].status,
                },
              }
              series.push(seriesItem)
            }
          }
        }
      }

      return {
        name: 'Duration',
        data: series,
      }
    },
  },
  watch: {
    timeRange() {
      this.loadData()
    },
    onlyErrors() {
      this.loadData()
    },
  },
  beforeMount() {
    if (this.isCurrentUserHost === true) {
      this.onlyErrors = true
    }
  },
  mounted() {
    if (this.hasGroupQuery) {
      this.onlyErrors = true // Set errors only filter if group filter is activated
    }

    this.loadData()
    this.mounted = true
  },
  methods: {
    hasClientAnyErrors(jobName, client) {
      return this.filteredJobDurationData.some(x => x.jobName === jobName && x.client === client && x.status !== 0)
    },
    adjustByTimezone(dateTime) {
      const offset = this.$moment.tz.zone(this.asup.timezone).utcOffset(dateTime)
      return dateTime.clone().subtract(offset, 'minutes')
    },
    loadData() {
      this.isLoading = true

      const params = {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
        onlyErrors: this.onlyErrors,
      }

      VeeamService.getTaskSessionDurationListAsync(this.asup.id, params, { disableTenantFilter: true })
        .then(result => {
          this.jobDurationData = result.items
        }).finally(() => {
          this.isLoading = false
        })
    },
    calculateChartHeight(dataCount) {
      const heightPerItem = 45
      const chartHeightNoData = 150

      if (dataCount === 0) {
        return `${chartHeightNoData}px`
      }

      if (dataCount === 1) { // there is a bug that if chart contains less or equal 2 lines, the lines will be really small.
        return `${dataCount * heightPerItem * 2.2}px`
      }
      if (dataCount <= 2) { // there is a bug that if chart contains less or equal 2 lines, the lines will be really small.
        return `${dataCount * heightPerItem * 1.5}px`
      }

      return `${dataCount * heightPerItem}px`
    },
  },
}
</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
